'use strict'

const _ = require('lodash')

const REPEATER_TYPE = 'wysiwyg.viewer.components.Repeater'
const DELIMITER = '__'
const getUniqueDisplayedId = (originalId, itemId) => originalId + DELIMITER + itemId

function generateGhostCompId(compId, controllerId, itemId) {
    const ghostId = compId + controllerId
    return itemId ? getUniqueDisplayedId(ghostId, itemId) : ghostId
}

function getChildrenForGhost(ghosts, childRoles, controllerId) {
    return _.map(childRoles, role => generateGhostCompId(ghosts[role].id, controllerId))
}

function buildDisplayedOnlyComp({fallbackStructure, comp, compRole, repeaterId, controllerId, parentId, item}) {
    return _.assign({}, comp, {
        id: compRole,
        structureId: generateGhostCompId(comp.id, controllerId, item),
        data: comp.data || {},
        design: comp.design || {},
        type: comp.componentType,
        displayedOnlyComponents: [],
        isDisplayed: true,
        displayedRoot: generateGhostCompId(parentId, controllerId),
        children: _.map(comp.children, child => generateGhostCompId(fallbackStructure[child].id, controllerId, item)),
        parent: generateGhostCompId(parentId, controllerId, parentId === repeaterId ? null : item)
    })
}

function getChildren(parent) {
    return _.map(_.get(parent, 'children', []), childRole => ({childRole, parentId: parent.id}))
}

function createGhostDisplayedOnlyComps({fallbackStructure, repeater, displayedOnlyComps, controllerId}) {
    const repeaterItems = _.get(repeater, 'data.items')

    let repeaterChildren = getChildren(repeater)
    while (repeaterChildren.length > 0) {
        const {childRole, parentId} = repeaterChildren.shift()
        const childComp = _.get(fallbackStructure, childRole)

        const displayedCompsForRole = _.map(repeaterItems, item => buildDisplayedOnlyComp({
            fallbackStructure,
            comp: childComp,
            compRole: childRole,
            repeaterId: repeater.id,
            controllerId,
            parentId,
            item
        }))

        displayedOnlyComps.set(childRole, displayedCompsForRole)

        const children = getChildren(childComp)
        repeaterChildren = repeaterChildren.concat(children)
    }

    return displayedOnlyComps
}

function getConnectionRoles(compIds, connections) {
    return _.mapValues(connections, connectionsList => new Set(_.keys(connectionsList)))
}

function getDataMap(components) {
    return _(components)
        .map('data')
        .compact()
        .omitBy(_.isEmpty)
        .keyBy('id')
        .value()
}

const getCompIdByCompRole = (connectionsMap, controllerId, componentRole) =>
    _.keys(_.get(connectionsMap, [controllerId, componentRole]))[0]

const getGhostParentId = (parentId, controllerId, fallbackStructure, compRole, realConnections) => {
    if (parentId) {
        return generateGhostCompId(parentId, controllerId)
    }

    const parentRole = _.findKey(fallbackStructure, potentialParent => _.includes(potentialParent.children, compRole))
    return getCompIdByCompRole(realConnections, controllerId, parentRole) || null
}

const createGhostComponentsAndConnections = (rawGhosts, parentMap, controllerId, displayedOnlyComps, ghostComps, ghostConnections, fallbackStructure, realConnections) => {
    _.forEach(rawGhosts, (comp, compRole) => {
        const parentId = parentMap[compRole]
        const ghostId = generateGhostCompId(comp.id, controllerId)

        const displayedOnly = displayedOnlyComps.get(compRole)
        const displayedOnlyComponents = _.map(displayedOnly, 'structureId')

        _.forEach(displayedOnly, displayedOnlyComp => {
            const displayedOnlyCompId = displayedOnlyComp.structureId
            delete displayedOnlyComp.structureId
            _.set(ghostComps, displayedOnlyCompId, displayedOnlyComp)
            _.set(ghostConnections, [controllerId, compRole, displayedOnlyCompId], null)
        })

        const ghostComp = _.assign({}, comp, {
            id: compRole,
            data: comp.data || {},
            design: comp.design || {},
            parent: getGhostParentId(parentId, controllerId, fallbackStructure, compRole, realConnections),
            type: comp.componentType,
            children: getChildrenForGhost(rawGhosts, comp.children || [], controllerId),
            displayedOnlyComponents,
            isDisplayed: !displayedOnlyComps.has(compRole),
            displayedRoot: _.get(comp, 'displayedRoot', null)
        })

        _.set(ghostConnections, [controllerId, compRole, ghostId], null)
        _.set(ghostComps, ghostId, ghostComp)
    })
}

function getGhostCompAndConnectionsModels(widgetsRawStructures, RMIData) {
    const ghostComps = {}
    const ghostConnections = {}
    const displayedOnlyComps = new Map()
    const parentMap = {}
    const connectionRoles = getConnectionRoles(_.keys(RMIData.components), RMIData.connections, RMIData.components)
    const dataMap = getDataMap(RMIData.components)
    _.forEach(connectionRoles, (existingConnections, controllerId) => {
        const controllerType = _.get(dataMap, [controllerId, 'controllerType'])
        if (!controllerType) {
            return
        }
        const fallbackStructure = _.get(widgetsRawStructures, [controllerType], {})
        const ghosts = _.reduce(fallbackStructure, (result, comp, compRole) => {
            if (!existingConnections.has(compRole)) {
                const children = _.get(comp, 'children')
                if (!_.isEmpty(children)) {
                    _.forEach(children, child => _.assign(parentMap, {[child]: _.get(comp, 'id')}))
                }

                if (comp.componentType === REPEATER_TYPE) {
                    createGhostDisplayedOnlyComps({
                        fallbackStructure,
                        repeater: comp,
                        displayedOnlyComps,
                        controllerId
                    })
                }

                return _.assign(result, {[compRole]: comp})
            }
            return result
        }, {})
        createGhostComponentsAndConnections(ghosts, parentMap, controllerId, displayedOnlyComps, ghostComps, ghostConnections, fallbackStructure, RMIData.connections)
    })
    return {ghostComps, ghostConnections}
}

function getAppWidgetRawStructure(widget, appStudioWidgetsStructureUrl) {
    const applicationId = widget.applicationId
    const widgetId = widget.widgetId
    const ghostStructureModuleUrl = appStudioWidgetsStructureUrl[applicationId][widgetId]
    if (!ghostStructureModuleUrl) {
        return {}
    }
    return fetch(ghostStructureModuleUrl)
        .then(res => res.json())
        .then(structure => ({[widget.controllerType]: structure}))
}

function getWidgetData(widget) {
    let widgetId
    try {
        widgetId = JSON.parse(widget.data.settings).devCenterWidgetId
    } catch (e) {
    //do nothing
    }
    return {
        controllerType: widget.data.controllerType,
        applicationId: widget.data.applicationId,
        widgetId
    }
}

function getAppWidgetStructureData(appWidgetsComponents, appStudioWidgetsStructureUrl) {
    return _(appWidgetsComponents)
        .map(getWidgetData)
        .filter('widgetId')
        .keyBy('widgetId')
        .map(widget => getAppWidgetRawStructure(widget, appStudioWidgetsStructureUrl))
        .value()
}

async function getAllWidgetsRawStructures(appWidgetsComponents, appStudioWidgetsStructureUrl) {
    const allGhostStructuresArray = await Promise.all(getAppWidgetStructureData(appWidgetsComponents, appStudioWidgetsStructureUrl))
    return Object.assign({}, ...allGhostStructuresArray)
}

async function getGhostStructure(RMIData, appStudioWidgetsStructureUrl) {
    if (_.isEmpty(appStudioWidgetsStructureUrl)) {
        return {}
    }
    const appWidgetsComponents = _.filter(RMIData.components, ['type', 'platform.components.AppWidget'])
    if (_.isEmpty(appWidgetsComponents)) {
        return {}
    }
    const widgetsRawStructures = await getAllWidgetsRawStructures(appWidgetsComponents, appStudioWidgetsStructureUrl)
    return getGhostCompAndConnectionsModels(widgetsRawStructures, RMIData)
}

module.exports = {
    getGhostStructure
}
